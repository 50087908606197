const sortFn = (a,b) => {
    if(a.read_at && !b.read_at || a.read_at < b.read_at) return 1;
    if(!a.read_at && b.read_at || b.read_at > a.read_at) return -1;
    if(a.triggered_at < b.triggered_at) return -1
    if(a.triggered_at > b.triggered_at) return 1;
    if(a.created_at < b.created_at) return -1
    if(a.created_at > b.created_at) return 1;
    return 0;
}

const evntSortFn = (a,b) => a.time > b.time;

class Notifications {
    constructor() {
        this.namespaced = true;
        this.state = {
            alerts: [],
            events: []
        }

        this.getters = {
            getTickerAlerts: state => state.alerts,
            getUnreadAlerts: state => state.alerts.filter(a => !!a.triggered_at && !a.read_at),
            getTriggeredAlerts: state => state.alerts.filter(a => a.triggered_at),
            getActiveAlerts: state => state.alerts.filter(a => !a.triggered_at),
            getAlerts: state => state.alerts,
            getEvents: state => state.events,
        }
        this.actions = {
            async getAlerts({getters, commit, dispatch, rootGetters}, payload) {
                let user = rootGetters['authenticationStore/getUser'];
                let result = await this._vm.api.get(`/users/${user.id}/alerts`); 
                commit('setAlerts', result.alerts);
            },
            async getEvents({getters, commit, dispatch, rootGetters}, payload) {
                let result = await this._vm.api.get(`/events`); 
                commit('setEvents', result.events);
            },
            async updateAlert({getters, commit, dispatch, rootGetters}, payload) {
                let alertIndex = getters.getAlerts.findIndex(a => a.id === payload.id);
                if(alertIndex < 0) return;
                commit('updateAlert', {
                    index: alertIndex,
                    alert: payload
                });
            },
            
            async saveAlerts({getters, commit, dispatch, rootGetters}, payload) {
                let user = rootGetters['authenticationStore/getUser'];
                let result = await this._vm.api.post(`/users/${user.id}/alerts/${payload.ticker}`, payload.alerts);
                dispatch('getAlerts');
            },
        }
        
        this.mutations = {
            setAlerts(state, alerts){
                state.alerts = alerts.sort(sortFn);
            },
            updateAlert(state, data){
                this._vm.$set(state.alerts, data.index, data.alert)
                // state.alerts[data.index] = data.alert;
            },
            setEvents(state, events){
                state.events = events.sort(evntSortFn);
            },
            addEvent(state, event){
                event.is_new = true;
                state.events.unshift(event);
            },
            
        }
    }
}

export default Notifications;