const axios = require('axios').default;
axios.defaults.baseURL = `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}`;
function serialize(obj){
	return Object.keys(obj).map(function(k){
		return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])
	}).join('&');
}

const Api = {
    install: (Vue, options) => {
        Vue.prototype.api = {
            get: async (url, data, opts) => {
                if(data){
                    url += '?' + serialize(data)
                }
        
                try {
                    let response = await axios({
                        method: 'GET',
                        url,
                        ...opts
                    }); 
                    if(response.status === 200){
                        return response.data.data || {};
                    }
                    throw response.data.msg;
                } catch(err){
                    throw err;
                }
            },
            post: async (url, data, opts) => {
                
                try {
                    let response = await axios({
                        method: 'POST',
                        url,
                        data,
                        ...opts
                    }); 
                    if(response.status === 200){
                        return response.data.data || {};
                    }
                    throw response.data.msg;
                } catch(err){
                    throw err;
                }
            },
            put: async (url, data, opts) => {
                
                try {
                    let response = await axios({
                        method: 'PUT',
                        url,
                        data,
                        ...opts
                    }); 
                    if(response.status === 200){
                        return response.data.data || {};
                    }
                    throw response.data.msg;
                } catch(err){
                    throw err;
                }
            },
            delete: async (url, id, opts) => {
                if(id){
                    url += '/' + id;
                }
                try {
                    let response = await axios({
                        method: 'DELETE',
                        url: url,
                        ...opts
                    });
                
                    if(response.status === 200){
                        return response.data.msg;
                    }
                    throw response.data.msg;
                } catch(err){
                    throw err;
                }
            }
        }
    }
}


export default Api;