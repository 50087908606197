<template>
    <v-navigation-drawer 
        right 
        width="350"
        absolute
        permanent
        
    >   
        <div class="alert-center-header">
            <span class="text-subtitle-2">Alert Center</span>
        </div>
        <template v-for="a in triggeredAlerts">
            <alert :key="a.id" :alert="a"></alert>
            <v-divider :key="a.id + '_divider'"></v-divider>
        </template>

        <v-row v-if="!alerts.length">
            <v-col class="pa-8 text-centertext-subtitle-2">No Alerts Found</v-col>
        </v-row>
        </v-navigation-drawer>
</template>



<script>
    import { mapGetters, mapMutations } from 'vuex';
      import Alert from './Alert.vue';
    export default {
        name: 'App',
        data: () => ({

        }),
        components: {
            Alert
        },
        computed: {
            ...mapGetters({
                alerts: 'notificationsStore/getAlerts',
                triggeredAlerts: 'notificationsStore/getTriggeredAlerts'
            }),
        },
        
    }
</script>

<style >
    
    .alert-center-header{
        padding: 10px;
        background-color: #2553be;
        color: white;
    }
</style>
