module.exports = {
    settings: [{ 
        type: "join", 
        key: 'AND',
        value: [
            { 
                type: "value", 
                key: 'last_price',
                value: 5
            }, 
            { 
                type: "value", 
                key: 'd1_rrvol',
                value: 1
            },
            { 
                type: "value", 
                key: 'd1_strength',
                value: 1.5
            },
            { 
                type: "value", 
                key: 'atr',
                value: 2
            },
            { 
                type: "value", 
                key: 'above_below_mas',
                value: 1
            }, 
        ]
    }, 
    { 
        type: "join", 
        key: 'OR',
        value: [
            { 
                type: "value", 
                key: 'ath',
                value: 1
            },
            { 
                type: "value", 
                key: 'heiken_ashe_trend',
                value: 1
            },
            { 
                type: "value", 
                key: 'crossed_d1_ma',
                value: 1
            }, 
            { 
                type: "value", 
                key: 'cross_trend_daily',
                value: 1
            }, 
            { 
                type: "value", 
                key: 'cross_horizontal_support',
                value: 1
            }, 
        ]
    }],
    name: 'End of Day',
    color: '#85E3FF',
    key: 'endOfDay'
}