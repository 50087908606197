class Base {
    constructor() {
        this.namespaced = true;
        this.state = {
            viewports: ['notifications', 'scan', 'stock'],
        }

        this.getters = {
            getViewports: state => state.viewports
        }
        this.mutations = {
            setViewports(state, viewports){
                state.viewports = viewports;
            }
        }
    }
}

export default Base;