const { DateTime } = require("luxon");
const Scanners = require("../scanners/index.js");
import { EventBus } from '../../modules/EventBus';


class Dashboard {
    constructor() {
        this.namespaced = true;
        this.state = {
            tickers: {},
            active_ticker: null,
            active_scanner: null,
            chart_date: null, 
            selected_timeframe: 'day',
            timeframes: ['minute', 'day'], 
            watchlist: [],
  
            active_ticker_interval: {},
            sector_colors: {
                SPY: '#363C4F',
                XLE: '#1abc9c',
                XLF: '#2ecc71',
                XLI: '#3498db',
                XLV: '#9b59b6',
                IYR: '#f1c40f',
                XLK: '#e67e22',
                XLU: '#e74c3c',
                XLB: '#e84393',
                XLP: '#273c75',
                XLY: '#006266',
                XTL: '#e0e0e0'
            }
        }
        this.getters = {
            
            isLoaded: state => (ticker) =>  ticker && state.tickers[ticker] && state.tickers[ticker] && state.tickers[ticker].isLoaded,
            getSelectedTimeframe: state => state.selected_timeframe,
            getTickerList: state => Object.keys(state.tickers),
            getActiveTicker: state => state.active_ticker,
            // getTicker: state => (ticker) => ticker && state.tickers[ticker] ? state.tickers[ticker]
            getChart: state => (ticker, timeframe, current_time) => {
                if(!state.tickers[ticker]) return {};
                // if(isNaN(periods)) return state.tickers[ticker].timeframes[timeframe];
                let t =  JSON.parse(JSON.stringify(state.tickers[ticker].timeframes[timeframe]));
                
                t.prices = t.prices?.filter(p =>  !current_time || p.time <= current_time)
                
                if(timeframe == 'day' && current_time && t.prices){
                    let current_bar = t.prices[t.prices.length - 1];
                    let current_price = state.tickers[ticker].timeframes['minute'].prices?.filter(p =>  p.time <= current_time && p.time > current_bar.time).reduce( (o,n, i) => {
                        o.time = n.time;
                        o.high = !o.high || o.high < n.high ? n.high: o.high;
                        o.low = !o.low || o.low > n.low ? n.low: o.low;
                        o.close = n.close;
                        o.open = i == 0  ? n.open : o.open
                        o.volume = o.volume ? o.volume + n.volume : n.volume;
                        return o;
                    }, t.prices[t.prices.length - 1]);
                    t.prices[t.prices.length - 1] = current_price
                }

                return t;
            },
            getMetrics: state => (ticker, timeframe, periods) => {
                if(!state.tickers[ticker]) return {};
                if(isNaN(periods)) return state.tickers[ticker].metrics;
                return state.tickers[ticker].metrics;
            },
            // getDailyChart: state => (ticker, periods) => {
            //     if(!state.tickers[ticker]) return {};
            //     if(isNaN(periods)) return state.tickers[ticker].timeframes.day.daily;
            //     return state.tickers[ticker].timeframes.day.daily;
            // },
            // getIntradayTrendsChart: state => (ticker, timeframe = 'day', periods) => {
            //     if(!ticker || !state.tickers[ticker] || !state.tickers[ticker].timeframes[timeframe]?.daily  ) return {};
            //     if(isNaN(periods)) return state.tickers[ticker].timeframes[timeframe].daily;

            //     if(timeframe == 'day'){
            //         return state.tickers[ticker].timeframes[timeframe].daily;
            //     } else if(timeframe === 'minute'){
            //         let vals = state.tickers[ticker].timeframes[timeframe].daily;
            //         let index = periods > 3 ? periods - 3 : 0; 

            //         let time = vals.tick_data[index].time
                    
            //         let trends =  {
            //             trends: {
            //                 maximas: vals.trends.maximas.filter(cl => cl.end.time <= time),
            //                 minimas: vals.trends.minimas.filter(cl => cl.end.time <= time)
            //             },
            //             horizontal_support: {
            //                 maximas: vals.horizontal_support.maximas.filter(cl => cl.time <= time),
            //                 minimas: vals.horizontal_support.minimas.filter(cl => cl.time <= time)
            //             },
            //             consolidationPeriods: vals.consolidationPeriods.filter(cp => cp.ending_point.time <= time)
            //         }
                    
            //         return trends ;
            //     }

            //     return state.tickers[ticker].timeframes[timeframe].daily;

            // },
            // getIntradayChart: state => (ticker, timeframe = 'day', periods) => {
            //     if(!ticker || !state.tickers[ticker] || !state.tickers[ticker].timeframes || !state.tickers[ticker].timeframes[timeframe]?.intraday  ) return {};
                
            //     console.log("getIntradayChart", state.tickers[ticker].timeframes[timeframe]?.intraday)
            //     if(isNaN(periods)) return state.tickers[ticker].timeframes[timeframe]?.intraday;
                

            //     if(timeframe == 'day'){
            //         let vals = JSON.parse(JSON.stringify(state.tickers[ticker].timeframes[timeframe].intraday));
                    
            //         let ticks = state.tickers[ticker].timeframes['minute'].intraday.ticks.filter((v, i) => i <= periods);
                    
            //         let high = Math.max(...ticks.map(t => t.high));
            //         let low = Math.min(...ticks.map(t => t.low));
            //         let open = ticks[0].open;
            //         let close = ticks[ticks.length-1].close;
            //         let volume = ticks.reduce((a,b) => a + b.volume, 0);

            //         vals.ticks[vals.ticks.length-1].high = high;
            //         vals.ticks[vals.ticks.length-1].low = low;
            //         vals.ticks[vals.ticks.length-1].open = open;
            //         vals.ticks[vals.ticks.length-1].close = close;
            //         vals.ticks[vals.ticks.length-1].volume = volume;


            //         let spy_ticks = state.tickers[ticker].timeframes['minute'].intraday.spy_ticks.filter((v, i) => i <= periods);
                    
            //         let spy_high = Math.max(...spy_ticks.map(t => t.high));
            //         let spy_low = Math.min(...spy_ticks.map(t => t.low));
            //         let spy_open = spy_ticks[0].open;
            //         let spy_close = spy_ticks[spy_ticks.length-1].close;
            //         let spy_volume = spy_ticks.reduce((a,b) => a + b.volume, 0);

            //         vals.spy_ticks[vals.spy_ticks.length-1].high = spy_high;
            //         vals.spy_ticks[vals.spy_ticks.length-1].low = spy_low;
            //         vals.spy_ticks[vals.spy_ticks.length-1].open = spy_open;
            //         vals.spy_ticks[vals.spy_ticks.length-1].close = spy_close;
            //         vals.spy_ticks[vals.spy_ticks.length-1].volume = spy_volume;


                    
            //         return vals;

            //     } else if(timeframe === 'minute'){
            //         let vals =  state.tickers[ticker].timeframes[timeframe].intraday;
                    
            //         return {
            //             ticks: vals.ticks.filter((v, i) => i <= periods),
            //             spy_ticks: vals.spy_ticks.filter((v, i) => i <= periods),
            //             VWAP: vals.VWAP.filter((v, i) => i <= periods),
            //             ema3: vals.ema3.filter((v, i) => i <= periods),
            //             ema8: vals.ema8.filter((v, i) => i <= periods),
            //             ema21: vals.ema21.filter((v, i) => i <= periods),
            //             // force_short: vals.force_short.filter((v, i) => i <= periods),
            //             // force_long: vals.force_long.filter((v, i) => i <= periods),
            //         }

            //     }


            //     return state.tickers[ticker].timeframes[timeframe]?.intraday

            // },
            getRRVol: (state, getters, rootState) =>  (ticker, timeframe = 'day', current_time) => {

                if(!ticker || !state.tickers[ticker] || !state.tickers[ticker].timeframes[timeframe]?.prices?.length) return {};
                
                return state.tickers[ticker].timeframes[timeframe].prices.filter(p => !current_time || p.time < current_time).map(t => {
                    return {
                        time: t.time,
                        rrvol: t.rrvol
                    }
                })


                // if(isNaN(periods)) return state.tickers[ticker].timeframes[timeframe].prices.map(t => {
                //     return {
                //         time: t.time,
                //         rrvol: t.rrvol
                //     }
                // })


    
                // console.log("periods", periods);
                // console.log("state.tickers[ticker].timeframes[timeframe].prices", state.tickers[ticker].timeframes[timeframe].prices);



            
                // if(timeframe == 'day'){


                //     let current_scan_value = rootState.scannerStore.current_scan.find(cs => cs.symbol === ticker);
                //     console.log("current_scan_value", current_scan_value)
                //     console.log("state.tickers[ticker].timeframes[timeframe]", state.tickers[ticker].timeframes[timeframe])
                //     if(!current_scan_value) return state.tickers[ticker].timeframes[timeframe].rrvol;

                //     let rvol = JSON.parse(JSON.stringify(state.tickers[ticker].timeframes[timeframe].rrvol));

                //     let last_rvol_val = {
                //         time: rvol.slice(-1)[0].time,
                //         rRVol: current_scan_value.day_rrvol
                //     }
                //     rvol.splice(-1, 1,  last_rvol_val)
                //     return rvol;
                // } else if(timeframe === 'minute'){
                    
                //     let vals =  state.tickers[ticker].timeframes[timeframe].rrvol;
                //     return vals.filter((v, i) => i <= periods)
                // }
        
            },
            getRRS: (state, getters, rootState) =>  (ticker, timeframe = 'day', current_time) => {
                
                if(!ticker || !state.tickers[ticker] || !state.tickers[ticker].timeframes[timeframe]?.prices?.length) return {};
                // if its real trading we wont pass in periods
                
                return state.tickers[ticker].timeframes[timeframe].prices.filter(p => !current_time || p.time < current_time).map(t => {
                    return {
                        time: t.time,
                        relative_strength: t.relative_strength,
                        overall_strength: t.overall_strength,
                    }
                })




                // if(isNaN(periods)) return state.tickers[ticker].timeframes[timeframe].prices.map(t => {
                //     return {
                //         time: t.time,
                //         relative_strength: t.relative_strength,
                //         overall_strength: t.overall_strength,
                //     }
                // })


             
                
                // let vals =  state.tickers[ticker].timeframes[timeframe].RRS
                // if(timeframe == 'day'){
                //     let current_scan_value = rootState.scannerStore.current_scan.find(cs => cs.symbol === ticker);
                //     console.log("current_scan_value", current_scan_value)
                //     if(!current_scan_value) return state.tickers[ticker].timeframes[timeframe].RRS;
                    
                //     let rs = JSON.parse(JSON.stringify(state.tickers[ticker].timeframes[timeframe].RRS));
                    
                //     let last_rs_val = {
                //         time: rs.relative_strength.slice(-1)[0].time,
                //         value: current_scan_value.day_relative_strength
                //     }
                //     rs.relative_strength.splice(-1, 1,  last_rs_val)
                //     return rs;
                // } else if(timeframe === 'minute'){
                //     return {
                //         // etf_atr_value: vals.etf_atr_value,
                //         stock_atr_value: vals.stock_atr_value,
                //         spy_atr_value: vals.spy_atr_value,
                //         // etf_data: vals.etf_data.filter((d,i) => i <= periods),
                //         relative_strength: vals.relative_strength.filter((d,i) => i <= periods),
                //         spy_data: vals.spy_data.filter((d,i) =>  i <= periods),
                //         stock_data: vals.stock_data.filter((d,i) => i <= periods)
                //     }
                // }
                // return state.tickers[ticker].timeframes[timeframe].RRS;
            },
            getPressure: (state, getters, rootState) =>  (ticker, timeframe = 'day', periods) => {
                if(!ticker || !state.tickers[ticker] || !state.tickers[ticker].timeframes[timeframe]?.pressure  ) return {};
                // if its real trading we wont pass in periods
                if(isNaN(periods)) return state.tickers[ticker].timeframes[timeframe]?.pressure;
                
                let vals =  state.tickers[ticker].timeframes[timeframe].pressure
                return vals;
                if(timeframe == 'day'){
                    state.tickers[ticker].timeframes[timeframe].pressure
                    // let current_scan_value = rootState.scannerStore.current_scan.find(cs => cs.symbol === ticker);
                    // console.log("current_scan_value", current_scan_value)
                    // if(!current_scan_value) return state.tickers[ticker].timeframes[timeframe].Pressure;
                    
                    // let pressure = JSON.parse(JSON.stringify(state.tickers[ticker].timeframes[timeframe].Pressure));
                    // TODO need to add pressure power to scan.. 
                    // let last_rs_val = {
                    //     time: pressure.slice(-1)[0].time,
                    //     value: current_scan_value.day_pressure
                    // }
                    // rs.relative_strength.splice(-1, 1,  last_rs_val)
                    // return rs;
                } else if(timeframe === 'minute'){
                    state.tickers[ticker].timeframes[timeframe].pressure
                    // return {
                    //     etf_atr_value: vals.etf_atr_value,
                    //     stock_atr_value: vals.stock_atr_value,
                    //     spy_atr_value: vals.spy_atr_value,
                    //     etf_data: vals.etf_data.filter((d,i) => i <= periods),
                    //     relative_strength: vals.relative_strength.filter((d,i) => i <= periods),
                    //     spy_data: vals.spy_data.filter((d,i) =>  i <= periods),
                    //     stock_data: vals.stock_data.filter((d,i) => i <= periods)
                    // }
                }
                return state.tickers[ticker].timeframes[timeframe].RRS;
            },


            getDetails: state =>  (ticker) => ticker && state.tickers[ticker] ? state.tickers[ticker].details : {},
            getSectorColors: state =>  state.sector_colors,
            getScanners: state =>  Object.values(state.scanners),
            getScannerList: state =>  Object.values(state.scanners).map(s => s.name),
            getActiveScanner: state => state.active_ticker,
            getScanConfigurations: state => state.scanConfigurations,
            getWatchlist: state => state.watchlist,
            getChartDate: state => state.chart_date
        }, 
        this.actions = {
            async updateTicker({state, commit, dispatch}, data){
                // if(state.active_ticker !== data.symbol) return;
                if(!state.tickers[data.symbol]) return;
                await dispatch('getDashboard', data.symbol)    
            }, 
            async setTicker({state, commit, dispatch}, data){
                commit('setTicker', data.ticker);
                await dispatch('loadTickerData', data.ticker);
                if(data.set_active){
                    commit('setActiveTicker', {
                        ticker: data.ticker
                    }); 
                }
            
            },
            async loadTickerData({state, commit, dispatch}, ticker){
                let t = ticker || state.active_ticker;
                if(!t) return;
                commit('setLoading')
                dispatch('getDashboard', t)
                dispatch('getTicker', t)
            }, 

            async setDate({state, commit, dispatch}, data){
                commit('setDate',  data.date);
                
                dispatch('loadTickerData');
            },


            async getTicker({state, commit, dispatch}, ticker){

                let results = await this._vm.api.get(`/tickers/symbol/${ticker}`);

                let data = {
                    isLoaded: true,
                    ...results
                };
                commit('setDetails', {
                    ticker,
                    data
                });
            }, 

            async setUpActiveTickerInterval({state, commit, dispatch}, ticker){
                // let interval =  window.setInterval(() => {
                //     if(!state.active_ticker) return;
                //     dispatch('loadTickerData');
                // }, 1000 * 20);

              //  commit('setActiveTickerInterval', interval)
            }, 


            async getDashboard({state, commit, dispatch}, ticker){

                let result = await this._vm.api.get(`/tickers/${ticker}/dashboard`, {
                    date: state.chart_date
                });
                commit('setDashboard', {
                    ticker,
                    data: { 
                        ...result.timeframes,
                    }
                });

                commit('setMetrics', {
                    ticker,
                    data: { 
                        ...result.current,
                    }
                });

            }, 

            
            async subscribe({state, commit, dispatch}, ticker){
                let result = await this._vm.api.get(`/tickers/${ticker}/subscribe`);
            }, 
            async subscribeETFs(){
                let results = await this._vm.api.get(`/tickers/etfs/subscribe`);
            }, 
            setLoaded({commit}) {
                commit(types.SET_LOADED, true);
            },
            async setSelectedTimeframe({state, commit, dispatch}, timeframe){
                
                commit('setTimeframe', timeframe);
                // commit('setLoading')

             //   await dispatch('loadTickerData')
            }
        }
        this.mutations = {
            setLoading(state, field){
                //if(state.tickers[state.active_ticker][field] && state.tickers[state.active_ticker][field].isLoaded) state.tickers[state.active_ticker][field].isLoaded = false;
                if(state.tickers[state.active_ticker] && state.tickers[state.active_ticker].isLoaded) state.tickers[state.active_ticker].isLoaded = false;
            }, 
            addToWatchlist(state, data){
                let index = state.watchlist.findIndex(w => w.ticker === data.ticker);
                if(index >= 0){
                    this._vm.$set(state.watchlist, index, data);
                } else {
                    state.watchlist.push(data);
                }
            }, 
            setDate(state, date){
                state.chart_date = date; 
            }, 
            removeFromWatchlist(state, ticker){
                state.watchlist.splice(state.watchlist.findIndex(w => w.ticker === ticker), 1);
            }, 
            deleteWatchlist(state, ticker){
                state.watchlist = [];
            }, 
            setScanner(state, scanner){
                this._vm.$set(state.scanners, scanner.key, scanner);
                state.scanners[scanner.key] = scanner
            }, 
            setActiveTickerInterval(state, interval){
                state.active_ticker_interval = interval
            }, 
            setLoaded(state, isLoaded) {
                state.site_loaded = isLoaded;
            },
            clearAllTabs(state, isLoaded){
                state.active_ticker = '';
                state.tickers = {};
            },
            setTicker(state, ticker) {
                this._vm.$set(state.tickers, ticker, {
                    details: {
                        isLoaded: false,
                        data: []
                    },
                    isLoaded: false,
                    timeframes: {
                        day: {},
                        minute: {},
                    }
                });

                EventBus.$emit('subscribe', ticker)
            },

            removeTickerInfo(state, data){
                EventBus.$emit('unsubscribe', ticker)
                this._vm.$set(state.tickers, data.ticker, {});
            }, 
            setActiveTicker(state, data) {
                this._vm.$set(state, 'active_ticker', data.ticker || null)

                let keys = Object.keys(state.tickers);
                for(let i = 0; i < keys.length; i++){
                    if(keys[i] === 'SPY') continue;
                    if(keys[i] === data.ticker) continue;
                    state.tickers[keys[i]].timeframes = {
                        day: {},
                        minute: {},
                    }

                }                

            },
            setDetails(state, data){
                if(!state.tickers[data.ticker]) return;
                this._vm.$set(state.tickers[data.ticker], 'details', data.data);
            },
            setDailyChart(state, data){
             
                if(!state.tickers[data.ticker]) return;
             
                this._vm.$set(state.tickers[data.ticker], 'daily', data.data);
            },
            setRRVol(state, data){
                if(!state.tickers[data.ticker]) return;
                this._vm.$set(state.tickers[data.ticker], 'RRVol', data.data);
            },


            setIntradayChart(state, data){
                if(!state.tickers[data.ticker]) return;
                this._vm.$set(state.tickers[data.ticker], 'intraday', data.data);
            },
            setRRS(state, data){
                if(!state.tickers[data.ticker]) return;
                this._vm.$set(state.tickers[data.ticker], 'RRS', data.data);
            },
            setDashboard(state, data){
                if(!state.tickers[data.ticker]) return;
                this._vm.$set(state.tickers[data.ticker], 'timeframes', data.data);
                this._vm.$set(state.tickers[data.ticker], 'isLoaded', true);
            },
            setMetrics(state, data){
                if(!state.tickers[data.ticker]) return;
                this._vm.$set(state.tickers[data.ticker], 'metrics', data.data);
            },
            setScanResutls(state, data){
                if(!state.tickers[data.ticker]) return;
                this._vm.$set(state.tickers[data.ticker], 'scan_results', data.data);
            },
            setIsLoaded(state, isLoaded){
                state.isLoaded = isLoaded;
            },
            removeTicker(state, data){
                if( data.ticker == state.active_ticker){
                    state.active_ticker = null;
                }
                this._vm.$delete(state.tickers, data.ticker);
            },
            setTimeframe(state, timeframe){
                state.selected_timeframe = timeframe;
            }, 
            updatePrice(state, payload){
                
                if(!state.tickers[payload.ticker]) return;
                
                //day
                
                
                // let last_price = state.tickers[payload.ticker].timeframes.day.prices[state.tickers[payload.ticker].timeframes.day.prices.length -1];
                
                // if(last_price.time != payload.time){
                //     let new_price = JSON.parse(JSON.stringify(last_price));
                //     new_price.time = payload.time;
                //     new_price.volume = payload.volume;
                //     new_price.high = payload.high;
                //     new_price.open = payload.open;
                //     new_price.close = payload.close;
                //     state.tickers[payload.ticker].timeframes.day.prices.push(new_price);
                // } else {
                //     let new_price = {};
                //     new_price.volume = payload.volume;
                //     new_price.high = Math.max(last_price.high, payload.high);
                //     new_price.low = Math.min(last_price.low, payload.low);
                //     new_price.close = payload.close;
                //     state.tickers[payload.ticker].timeframes.day.prices[state.tickers[payload.ticker].timeframes.day.prices.length -1].volume = payload.volume;
                //     state.tickers[payload.ticker].timeframes.day.prices[state.tickers[payload.ticker].timeframes.day.prices.length -1].high = payload.high;
                //     state.tickers[payload.ticker].timeframes.day.prices[state.tickers[payload.ticker].timeframes.day.prices.length -1].low = payload.low;
                //     state.tickers[payload.ticker].timeframes.day.prices[state.tickers[payload.ticker].timeframes.day.prices.length -1].close = payload.close;
        
                // }


                //minute
                let last_price = state.tickers[payload.ticker].timeframes.minute.prices[state.tickers[payload.ticker].timeframes.minute.prices.length -1];
                
                if(last_price.time != payload.time){
                    let new_price = JSON.parse(JSON.stringify(last_price));
                    new_price.time = payload.time;
                    new_price.volume = payload.volume;
                    new_price.high = payload.high;
                    new_price.open = payload.open;
                    new_price.low = payload.low;
                    new_price.close = payload.close;
                    state.tickers[payload.ticker].timeframes.minute.prices.push(new_price);
                } else {
                    let new_price = {};
                    new_price.volume = payload.volume;
                    new_price.high = payload.high;
                    new_price.low = payload.low;
                    new_price.close = payload.close;
                    state.tickers[payload.ticker].timeframes.minute.prices[state.tickers[payload.ticker].timeframes.minute.prices.length -1].volume = payload.volume;
                    state.tickers[payload.ticker].timeframes.minute.prices[state.tickers[payload.ticker].timeframes.minute.prices.length -1].high = Math.max(last_price.high, payload.high) ;
                    state.tickers[payload.ticker].timeframes.minute.prices[state.tickers[payload.ticker].timeframes.minute.prices.length -1].low = Math.min(last_price.low, payload.low) ;
                    state.tickers[payload.ticker].timeframes.minute.prices[state.tickers[payload.ticker].timeframes.minute.prices.length -1].close = payload.close;

                }


                state.tickers[payload.ticker].timeframes.day.prices[state.tickers[payload.ticker].timeframes.day.prices.length -1].volume += payload.volume;
                state.tickers[payload.ticker].timeframes.day.prices[state.tickers[payload.ticker].timeframes.day.prices.length -1].high = payload.high;
                state.tickers[payload.ticker].timeframes.day.prices[state.tickers[payload.ticker].timeframes.day.prices.length -1].low = payload.low;
                state.tickers[payload.ticker].timeframes.day.prices[state.tickers[payload.ticker].timeframes.day.prices.length -1].close = payload.close;

                this._vm.$set(state.tickers[payload.ticker].timeframes.minute, 'volume_diff', payload.volume_diff * 100);
                this._vm.$set(state.tickers[payload.ticker].timeframes.minute, 'distance_diff', payload.distance_diff * 100);




            }, 
        }
    }
}

export default Dashboard;