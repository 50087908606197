module.exports = {
    settings: [{
        type: "join",
        key: 'AND', 
        value: [
            { 
                type: "value", 
                key: 'daily_move_atr',
                value: 1
            }, 
            { 
                type: "value", 
                key: 'last_price',
                value: 5
            }, 
            { 
                type: "value", 
                key: 'rrvol',
                value: 1.5
            },
            { 
                type: "value", 
                key: 'atr',
                value: 2
            }, 
            { 
                type: "value", 
                key: 'pullback_3_8',
                value: 1
            }
        ]
    },
    { 
        type: "join", 
        key: 'OR',
        value: [
            { 
                type: "value", 
                key: 'pullback_3_8',
                value: 1
            }
        ]
    }],
    name: 'Pullback',
    color: '#E18EBD',
    key: 'pullback'
}