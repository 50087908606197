export default [
	{
		name: "Authenticate",
		path: '/auth',
		component: () => import(/* webpackChunkName: "auth" */'../components/auth/Index.vue'),
		meta: {
			requiresAuth: false    
		}
	},
	
]