import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import api from './plugins/api';
import router from './router';
import store from './store';
const axios = require('axios').default;


Vue.use(api);
Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
  
  if (to.meta.requiresAuth && !store.getters['authenticationStore/isLoggedIn']) {
    next({
      path: 'login',
      query: { redirect: to.fullPath },
    })
  }
  next()
})


axios.interceptors.request.use(request => {
  request.headers.common.Authorization = store.getters['authenticationStore/getToken'];
  return request
})


// Vue.http.interceptors.push((request, next)  => {
 

  // next(function (response) {
  //   //TODO Fix this!
  //   if(response.body.status && response.body.status === 401){
  //     store.dispatch('authenticationStore/logout')
  //     // invalidate login
  //     // set not logged in flag,
  //     // show login modal,
  //     // repeat the requests
  //   }
  // }.bind(this));
// });



new Vue({
  vuetify,
  router, 
  store,
  render: h => h(App)
}).$mount('#app');

store.$api = api;