<template>
    <div class="notifications">
        
        <v-card v-for="(n) in notifications" :key="n.id" elevation="2" class="mb-2">
            <div class="alert-header" :class="n.type">
                <span class="text-subtitle-2" v-if="n.type == 'alert'">Alert Triggred!</span>
                <span  class="text-subtitle-2" v-if="n.type == 'scanner_update'">Scanner Updated!</span>
                <span  class="text-subtitle-2" v-if="n.type == 'event'">Event Triggered!</span>
                <span  class="text-subtitle-2" v-if="n.type == 'trade'">Trade Signal!</span>
            </div>
                <alert v-if="n.type == 'alert'" :key="n.id" :alert="n" type="notification" @dismiss="dismiss"></alert>
                <scanner-alert v-if="n.type == 'scanner_update'" :key="n.id" :alert="n" @dismiss="dismiss"></scanner-alert>
                <event-alert v-if="n.type == 'event'" :key="n.id" :alert="n" type="notification" @dismiss="dismiss"></event-alert>
                <trade-alert v-if="n.type == 'trade'" :key="n.id" :alert="n" type="notification" @dismiss="dismiss"></trade-alert>
        </v-card>
    </div>
</template>


<script>
    import { EventBus } from '../../modules/EventBus.js';
    import Trade from './TradeAlert.vue';
    import Alert from './Alert.vue';
    import ScannerAlert from './ScannerAlert.vue';
    import EventAlert from './EventAlert.vue';
    export default {
        name: 'Notifications',
        data: () => ({
            notifications: [],
            
        }), 
        components: {
            Alert,
            ScannerAlert,
            EventAlert
        }, 
        created(){ 
            EventBus.$on('show_notification', this.showNotification);
        },
        destroyed(){ 
           EventBus.$off('show_notification', this.showNotification); 
        },
        methods:{
            showNotification(data){
                if(data.type ===  'alert'){
                    let existing = this.notifications.find(n => n.id === data.id );
                    if(!existing){
                        this.notifications.push(data); 
                    }
                } else if(data.type ===  'scanner_update'){
                    let existing = this.notifications.findIndex(n => n.type  ===  'scanner_update' );
                    if(existing < 0){
                        this.notifications.push(data); 
                    } else {
                        
                        for (let i = 0; i < data.scanners.length; i++){
                            let scanIndex = this.notifications[existing].scanners.findIndex(n => n.name === data.scanners[i].name );
                            if(scanIndex >= 0){
                                this.notifications[existing].scanners[scanIndex].count += data.scanners[i].count;
                            } else {
                                this.notifications[existing].scanners.push(data.scanners[i]);
                            }
                        }
                    }
                } else if (data.type == 'event'){
                    this.notifications.push(data); 
                }


            },
            dismiss(alert_id){
                let existingIndex = this.notifications.findIndex(n => n.id === alert_id);
                this.notifications.splice(existingIndex, 1)
            },
           
        } 
    }
</script>
<style >
    .notifications{
        position: absolute;
        top: 10px;
        right: 10px;
        width: 350px;
        z-index: 10000;
    }
    .alert-header{
        padding: 10px;
       
    }

    .alert-header.alert{    
        background-color: #363c4f;
        color: white;
    }
    .alert-header.scanner_update{    
        background-color: #8e44ad;
        color: white;
    }
    .alert-header.event{    
        background-color: #D35400;
        color: white;
    }
</style>

