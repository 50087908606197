import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store'
// import  StatusMessages from '../plugins/StatusMessages.js';

Vue.use(VueRouter);

import baseRoutes from './base.js';
import authRoutes from './auth.js';



const routes = [].concat(
  baseRoutes,
  authRoutes
);


const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
});

export default router;
