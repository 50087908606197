import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#2F69FF',
            secondary: '#2553BE',
            accent: '#363C4F',
            error: '#E74D3C',
            success: '#2ECC70',
            neutral: '#EEF2F3'
          },
        },
      },
});
