class Positions {
    constructor() {
        this.namespaced = true;
        this.state = {
            
            results:{
                closed_positions: [],
                wins: 0,
                losses: 0,
                amount_won: 0,
                amount_lost: 0,
                total_profit: 0
            }, 
            open_positions: []
        }
        this.getters = {
            getOpenPositions: state => state.open_positions, 
            getResults: state => state.results, 
            
        }
        this.actions = {
        
        }
        this.mutations = {
            addOpenPosition(state, data){
                let index = state.open_positions.findIndex(s => s.ticker === data.ticker)
                if(index >= 0){
                    this._vm.$set(state.open_positions, index, data);
                } else {
                    state.open_positions.push(data); 
                }

            }, 
            skipPosition(state, data){
                state.results.closed_positions.push(data.position);
            }, 
            closePosition(state, data){
                
                let positionIndex = state.open_positions.findIndex(p => p.ticker === data.ticker); 
                if(positionIndex < 0) return;
                let position = state.open_positions[positionIndex]
                position.close_price = data.price;
                position.close_time = data.time;
                console.log("position", position)

                position.profit = position.type === 'long' ? (position.close_price - position.open_price) * position.shares : (position.open_price - position.close_price) * position.shares;
                
                position.duration = position.close_time - position.open_time;
                position.status = 'closed';

                state.results.closed_positions.push(position);
                state.results.total_profit += position.profit;

                if(position.profit > 0) {
                    state.results.wins++;
                    state.results.amount_won += position.profit;
                }
                if(position.profit < 0){
                    state.results.losses++;
                    state.results.amount_lost += position.profit;
                }

                
                state.open_positions.splice(positionIndex, 1); 
                
            }
        } 
    }
}


export default Positions;