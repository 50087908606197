class Scanner {
    constructor() {
        this.namespaced = true;
        this.state = {
            scans: [],
            watchlist: {},
            sectors: {},
            tickers: {},
            preconfigured_scans: [],
            columns: [],
            columeState: [],
            indicators: [],
            current_scan: [],
            timeframes: [{
                label: 'Minute',
                name: "M5"
            },
            {
                label: 'Hour',
                name: "H1"
            },
            {
                label: 'Day',
                name: "D1"
            }  
           ]
        }
        this.getters = {
            getScans: state => state.scans, 
            getWatchlist: state => state.watchlist, 
            getSectors: state => state.sectors, 
            getAllTickers: state => state.tickers, 
            getPreconfiguredScans: state => state.preconfigured_scans, 
            isLoaded: state => (ticker) =>  ticker && state.tickers[ticker] && state.tickers[ticker] && state.tickers[ticker].isLoaded,
            getCurrentScan: state => (ticker) => state.current_scan.find(cs => cs.symbol === ticker), 
            getColumns: state => state.columns.filter(c => c.field !=='configure'), 
            getColumnState: state => state.columnState, 
            getIndicators: state => state.indicators, 
            getTimeframes: state => state.timeframes
        }
        this.actions = {
            async getIndicators({getters, commit, dispatch, rootGetters}, payload){
                let result = await this._vm.api.get(`/scanner/indicators`); 
                
                commit('setIndicators', result.indicators);
            }, 

            async getUserScans({getters, commit, dispatch, rootGetters}, payload){
                let result = await this._vm.api.get(`/users/my-scans`); 
                commit('setScans', result.scans);
            }, 

            async getUserWatchlist({getters, commit, dispatch, rootGetters}, payload){
                let result = await this._vm.api.get(`/users/watchlist`); 
                commit('setWatchlist', result.watchlist);
            }, 
            async getSectors({getters, commit, dispatch, rootGetters}, payload){
                let result = await this._vm.api.get(`/scanner/sectors`); 
                commit('setSectors', result.sectors);
            }, 
            async getAllTickers({getters, commit, dispatch, rootGetters}, payload){
                let result = await this._vm.api.get(`/scanner/tickers`); 
                commit('setTickers', result.tickers);
            }, 

            async getPreconfiguredScans({getters, commit, dispatch, rootGetters}, payload){
                let result = await this._vm.api.get(`/scanner/preconfigured`); 
                console.log("result", result)
                commit('setPreconfiguredScans', result.scans);
            }, 

            async saveScan({getters, commit, dispatch, rootGetters}, payload){
                let result = {};
                if(payload.id){
                    result = await this._vm.api.put(`/scanner/${payload.id}`, payload); 
                } else {
                    result = await this._vm.api.post(`/scanner`, payload); 
                    payload.id = result.id;
                }
                commit('addScan', payload);
            } , 
            async deleteScan({getters, commit, dispatch, rootGetters}, id){
                let result = {};
                result = await this._vm.api.delete(`/scanner`, id);
                commit('deleteScan', id)
            } ,
            async addToWatchlist({state, commit, dispatch}, data){
                let result = await this._vm.api.post(`/users/watchlist`, data);
                console.log("result", result); 
                dispatch('getUserWatchlist')
            },
            async removeFromWatchlist({state, commit, dispatch}, data){
                if(!data.ticker) return;
                await this._vm.api.delete(`/users/watchlist`, data.ticker); 
                dispatch('getUserWatchlist')
            },
            async deleteWatchlist({state, commit, dispatch}, data){
                
                await this._vm.api.delete(`/users/watchlist`); 
                dispatch('getUserWatchlist');
            }, 


        }
        this.mutations = {
            setScan(state, data){
                state.current_scan = data;
            },
            setPreconfiguredScans(state, data){
                state.preconfigured_scans = data;
            },
            addScan(state, data){
                let index = state.scans.findIndex(s => s.id === data.id)
                if(index >= 0){
                    this._vm.$set(state.scans, index, data);
                } else {
                    state.scans.push(data); 
                }
            },
            setScans(state, data){
                state.scans = data
            },
            setWatchlist(state, data){
                state.watchlist = data
            },
            setSectors(state, data){
                state.sectors = data
            },
            setTickers(state, data){
                state.tickers = data
            },
            updateScanCache(state, data){
                let index = state.scans.findIndex(s => s.id === data.scan.id)
                if(index >= 0){
                    this._vm.$set(state.scans[index], 'cache', data.cache);
                } else {
                    state.scans.push(data); 
                }
            }, 
            setColumns(state, data){
                state.columns = data.filter(d => d.field !== "configure");
            }, 
            setColumnState(state, data){
                state.columnState = data;
            }, 
            deleteScan(state, data){
                let index = state.scans.findIndex(s => s.id === data.id);
                state.scans.splice(index, 1); 
            },
            setIndicators(state, data){
                state.indicators = data;
            }
        } 
    }
}


export default Scanner;