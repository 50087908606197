import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

const dataState = createPersistedState({
    paths: [
        // 'dashboardStore.watchlist', 
        // 'scannerStore.scans', 
        'scannerStore.columns',
        'authenticationStore.token', 
        'authenticationStore.user'
    ]
  })


Vue.use(Vuex);

import Dashboard from "./modules/dashboard.js";
import Scanner from "./modules/scanner.js";
import Authentication from "./modules/authentication.js";
import Notifications from "./modules/notifications.js";
import Positions from "./modules/positions.js";
import Base from "./modules/base.js";

const dashboardStore = new Dashboard();
const scannerStore = new Scanner();
const authenticationStore = new Authentication();
const notificationsStore = new Notifications();
const positionsStore = new Positions();
const baseStore = new Base();


export default new Vuex.Store({
    strict: process.env.NODE_ENV !== "production",
    modules: {
        dashboardStore,
        scannerStore,
        authenticationStore,
        notificationsStore,
        positionsStore,
        baseStore
    },
    plugins: [dataState]
});