export default [
	{
		name: "Base",
		path: '/',
		component: () => import(/* webpackChunkName: "dashboard" */'../components/base/Index.vue'),
		meta: {
			show_header: true,
			requiresAuth: true    
		}
	},
	{
		name: "ChannelMessages",
		path: '/channel-messages',
		
	    component: () => import(/* webpackChunkName: "import" */'@/components/channels/Index.vue'),
		meta: {
			show_header: true,
			requiresAuth: true
		}
	},
	{
		name: "Authenticate",
		path: '/auth',
		component: () => import(/* webpackChunkName: "auth" */'@/components/auth/Index.vue'), 
		meta: {
			show_header: false,
			requiresAuth: false    
		}
	},
	{
		name: "Login",
		path: '/login',
		component: () => import(/* webpackChunkName: "auth" */'../components/base/Login.vue'),
		meta: {
			requiresAuth: false    
		}
	},
	{
		name: "Logout",
		path: '/logout',
		component: () => import(/* webpackChunkName: "auth" */'../components/base/Logout.vue'),
		meta: {
			requiresAuth: false    
		}
	},
	{
		name: "Dashboard",
		path: '/dashboard',
		redirect: to => {
			return { path: '/' }
		}, 
		// component: () => import(/* webpackChunkName: "dashboard" */'../components/dashboard/Index.vue'),
		meta: {
			show_header: true,
			requiresAuth: true    
		}
	},
	{
		name: "Scanner",
		path: '/scanner',
		redirect: to => {
			return { path: '/' }
		}, 
		// component: () => import(/* webpackChunkName: "dashboard" */'../components/scanner/Index.vue'),
		meta: {
			show_header: true,
			requiresAuth: true    
		}
	},
	{
		name: "Spy",
		path: '/spy',
		
	    component: () => import(/* webpackChunkName: "spy" */'../components/spy/Index.vue'),
		meta: {
			show_header: true,
			requiresAuth: true
		}
	},
	{
		name: "Replay",
		path: '/replay',
		
	    component: () => import(/* webpackChunkName: "backtest" */'../components/backtest/Index.vue'),
		meta: {
			show_header: true,
			requiresAuth: true
		}
	},
	{
		name: "Analyze",
		path: '/analyze',
	    component: () => import(/* webpackChunkName: "analyze" */'../components/analyze/Index.vue'),
		meta: {
			show_header: true,
			requiresAuth: true
		}
	},
	{
		name: "Import",
		path: '/import-trades',
		
	    component: () => import(/* webpackChunkName: "import" */'../components/import_trades/Index.vue'),
		meta: {
			show_header: true,
			requiresAuth: true
		}
	},
	{
		name: "Base",
		path: '/:symbol/:date',
		component: () => import(/* webpackChunkName: "dashboard" */'../components/base/Index.vue'),
		meta: {
			show_header: true,
			requiresAuth: true    
		}
	},
	{
		name: "Base",
		path: '/:symbol',
		component: () => import(/* webpackChunkName: "dashboard" */'../components/base/Index.vue'),
		meta: {
			show_header: true,
			requiresAuth: true    
		}
	},
] 