<template>
    
        <div :key="alert.id" class="pa-2">
            <v-list >
                <v-list-item v-for="(s, i) in a.scanners" :key="i" two-line>
                    <v-list-item-avatar size="24">
                        <v-icon v-if="s.direction.toLowerCase() == 'long'" small dark class="success">mdi-trending-up</v-icon>
                        <v-icon v-if="s.direction.toLowerCase() == 'short'" small dark class="error">mdi-trending-down</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-subtitle-2">
                            {{s.name}}
                        </v-list-item-title>

                        <v-list-item-subtitle class="text-caption"> 
                            {{s.count}} Tickers Added
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
             <v-divider></v-divider>
            <div class="text-right pa-1">
                <v-btn color="error" @click="dismiss" small text>Dismiss</v-btn>
            </div>
        </div>
</template>
<script>
    import { EventBus } from '../../modules/EventBus.js';
    const { DateTime } = require("luxon");
    export default {
        name: 'App',
        data: () => ({
            a: {}
        }),
        props: ['alert'],
        computed: {},
        created(){
            this.a = JSON.parse(JSON.stringify(this.alert));
        }, 
        filters:{
            friendlyDate(time){
                if(!time) return '';
                return DateTime.fromMillis(time  * 1000).toRelative({unit: 'minutes'})
            },
            
        },
        methods: {
            dismiss(){
                this.$emit('dismiss', this.a.id);
            
            }
        },
        watch:{
            alert: {
                deep: true,
                handler(new_val, old){
                    this.a = new_val
                }
            }
        }
    }
</script>

<style scoped>
    .read{
        opacity: .50;
        background-color: #f5f5f5;
    }
    .notifcation-icon {
        padding-top: 15px;

    }
    .triggered-time{
        margin-top: 5px;
        opacity: .66 
    }
</style>

