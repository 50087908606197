class Authentication {
    constructor() {
        this.namespaced = true;
        this.state = {
            user: {},
            token: null,
            isLoggedIn: false,
        }

        this.getters = {
            getToken: state => state.token,
            getUser: state => state.user,
            isLoggedIn: state => !!state.token
        }
        this.actions = {
            authenticate({commit}, payload) {
                
                commit('setAuthenticated', payload.token);
                commit('setUser', payload.user);
            },
            logout({commit}, payload) {
                commit('setAuthenticated', null);
                commit('setUser', {});
            },

        }
        
        this.mutations = {
            setAuthenticated(state, token){
                state.token = token;
            },
            setUser(state, user){
                state.user = user
            },
            setColumnConfig(state, column_config){
                state.user.column_config = column_config
            }, 
            set_token_expiry(state, data){
                state.user.refresh_token_expiry = data.refresh_token_expiry
            }
        }
    }
}

export default Authentication;