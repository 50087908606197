<template>
    <v-row :key="alert.id" no-gutters :class="{read: a.read_at}" @click="viewTicker(true)" class="pt-2">
        <v-col cols="2" class="text-center pt-3">
            <span class="notifcation-icon" v-if="a.ScanFilter.datatype == 'List'">
                <v-avatar  size="24" v-if="a.value == 'Bearish'" color="error">
                    <v-icon small dark >mdi-arrow-down-thick</v-icon>
                </v-avatar>
                <v-avatar  size="24" color="success" v-if="a.value == 'Bullish'">
                    <v-icon small dark >mdi-arrow-up-thick</v-icon>
                </v-avatar>
            </span>

            <span class="notifcation-icon text-center"  v-if="a.ScanFilter.datatype == 'Boolean'">
                <v-avatar  size="24" color="success">
                    <v-icon small dark >mdi-check-bold</v-icon>
                 </v-avatar>
            </span>


            <span class="notifcation-icon text-center"  v-if="a.ScanFilter.datatype == 'Number'">
                <v-avatar  size="24" color="secondary">
                    <v-icon v-if="a.comparator == 'greaterThan'" dark >mdi-chevron-right</v-icon>
                    <v-icon v-if="a.comparator == 'lessThan'" dark >mdi-chevron-left</v-icon>
                    <v-icon v-if="a.comparator == 'equalTo'" small dark >mdi-equal</v-icon>
                 </v-avatar>
            </span>

        </v-col>
        <v-col class="py-2" >
            <div class="text-subtitle-2">{{a.Ticker.symbol}}</div>
            
            <div v-if="a.ScanFilter.datatype == 'Boolean'">
                {{a.timeframe | timeframe_name}} {{a.ScanFilter.name}}
            </div>

            <div v-if="a.ScanFilter.datatype == 'List'">
                  <span> {{a.timeframe | timeframe_name}} {{a.ScanFilter.name}}  </span>
            </div>

            <div v-if="a.ScanFilter.datatype == 'Number'">
                 <span> {{a.timeframe | timeframe_name}} {{a.ScanFilter.name}}  </span>
                
                <span>
                    <v-icon v-if="a.comparator == 'greaterThan'" small >mdi-chevron-right</v-icon>
                    <v-icon v-if="a.comparator == 'lessThan'" small >mdi-chevron-left</v-icon>
                    <v-icon v-if="a.comparator == 'equalTo'" small >mdi-equal</v-icon>
                </span>

                <span> {{a.value }}  </span>
               
            </div>
            <div class="text-caption triggered-time">
                Triggered {{a.triggered_at | friendlyDate}}
            </div>
            

            <div class="text-right mt-4 pr-3">
                <v-btn v-if="type === 'notification'" color="error" @click="dismiss" small text>Dismiss</v-btn>
                <v-btn v-if="type === 'notification'" @click="viewTicker(false)" depressed small color="primary">View {{a.Ticker.symbol}}</v-btn>
            </div>
        </v-col>
  
    </v-row>
</template>
<script>
    import { EventBus } from '../../modules/EventBus.js';
    const { DateTime } = require("luxon");
    export default {
        name: 'App',
        data: () => ({
            a: {}
        }),
        props: ['alert', 'type'],
        computed: {},
        created(){
            this.a = JSON.parse(JSON.stringify(this.alert));
        }, 
        filters:{
            friendlyDate(time){
                if(!time) return '';
                return DateTime.fromMillis(time  * 1000).toRelative({unit: 'minutes'})
            },
            timeframe_name(val){
                switch(val){
                    case 'minue': return 'M5';
                        case 'hour': return 'H1';
                        case 'day': return 'D1';
                }
            }
        },
        methods: {
            dismiss(){
                this.$emit('dismiss', this.a.id);
                this.markRead();

            },
            async markRead(){
                let response = await this.api.get(`/alerts/${this.a.id}/mark-read`);
                this.a.read_at = response.alert.read_at
                this.$store.dispatch('notificationsStore/getAlerts');
            },
            viewTicker(isAlertPanel){

                if(isAlertPanel && this.type === 'notification') return;

                this.$store.dispatch('dashboardStore/setTicker',  {
                    ticker: this.a.Ticker.symbol,
                    set_active: true
                });
                this.dismiss();
                this.markRead();

                EventBus.$emit('closeAlertDrawer');
            }
        }
    }
</script>

<style scoped>
    .read{
        opacity: .50;
        background-color: #f5f5f5;
    }
    .notifcation-icon {
        padding-top: 15px;

    }
    .triggered-time{
        margin-top: 5px;
        opacity: .66 
    }
</style>

