module.exports = {
    settings: [{
        type: "join",
        key: 'AND', 
        value: [
            { 
                type: "value", 
                key: 'daily_move_atr',
                value: 1
            }, 
            { 
                type: "value", 
                key: 'last_price',
                value: 5
            }, 
            { 
                type: "value", 
                key: 'd1_m5_rs_direction',
                value: 1
            }, 
            { 
                type: "value", 
                key: 'stacked_rs',
                value: 1
            }, 
            { 
                type: "value", 
                key: 'rrvol',
                value: 1.5
            },
            { 
                type: "value", 
                key: 'm5_strength',
                value: 1.5
            },
            { 
                type: "value", 
                key: 'atr',
                value: 2
            }
        ]
    },
    { 
        type: "join", 
        key: 'OR',
        value: [
            { 
                type: "value", 
                key: 'unusual_volume',
                value: 1.5
            }
        ]
    }],
    name: 'Volume',
    color: '#02B1E3',
    key: 'volume'
}