module.exports = {
    name: 'General',
    color: '#B0D896',
    key: 'general', 
    settings: { 
        minute: [{
            type: "join", 
            key: 'AND',
            value: [
                { 
                    type: "value", 
                    key: 'relative_strength',
                    value: 1
                }, 
                { 
                    type: "value", 
                    key: 'stacked_rs',
                    value: 1
                }, 
                { 
                    type: "value", 
                    key: 'rrvol',
                    value: 1.5
                }
            ]
        }, 
        { 
            type: "join", 
            key: 'OR',
            value: [
            
            ]
        }],
        hour: [{
            type: "join", 
            key: 'AND',
            value: []
        }, 
        { 
            type: "join", 
            key: 'OR',
            value: [
            ]
        }], 
        day: [{
            type: "join", 
            key: 'AND',
            value: [
                { 
                    type: "value", 
                    key: 'last_price',
                    value: 5
                }, 
                { 
                    type: "value", 
                    key: 'atr',
                    value: 2
                }, 
            ]
        }, 
        { 
            type: "join", 
            key: 'OR',
            value: [
                { 
                    type: "value", 
                    key: 'ath',
                    value: 1
                },
                { 
                    type: "value", 
                    key: 'heiken_ashe_trend',
                    value: 1
                },
                { 
                    type: "value", 
                    key: 'crossed_ma',
                    value: 1
                }, 
            ]
        }]
    }
   
}