<template>
  <v-app>
    <div>
      <v-app-bar
        v-if="isLoggedIn && $route.meta.show_header"
        class="app-bar"
        app
        color="accent"
        dark
      >
        
        <div>
      
          <v-container style="width: 500px" dense v-if="user.td_account_id">
            <v-row class="mr-3 text-left" no-gutters>
              <v-col style="flex: 0 1 100px;">Account:</v-col>
              <v-col style="flex: 1 0;">{{user.td_account_id}}</v-col>
            </v-row> 
            <v-row class="mr-3 text-left" v-if="isValidExpiry" no-gutters>
              <v-col style="flex: 0 1 100px;">Valid Until:</v-col>
              <v-col style="flex: 1 0;">{{user.refresh_token_expiry | formatDateTime}}</v-col>
            </v-row> 

            <v-row class="mr-3 text-left" v-else no-gutters>
              <v-col style="flex: 0 1 100px;">Valid Until:</v-col>
              <v-col style="flex: 1 0;">Access expired.</v-col>
            </v-row> 

          </v-container>
          <v-container dense v-else>
            <v-row class="mr-3" no-gutters>
              <v-col>Account not connected</v-col>
            </v-row> 
          </v-container>
        </div>
          
        <v-spacer></v-spacer>
  

        <v-btn small @click="authenticate" text >
                <span class="mr-2">Authenticate</span>
              </v-btn>
              
              <v-btn small to="/" text class="mr-2">
                <span >Scanner</span>
              </v-btn>
              <v-btn small to="/replay" text class="mr-2">
                <span >Replay</span>
              </v-btn>
              <v-btn small to="/analyze" text class="mr-2">
                <span >Analyze</span>
              </v-btn>

              <v-btn small to="/import-trades" text class="mr-2">
                <span >Import</span>
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text v-bind="attrs" v-on="on" elevation="0"  style="text-transform: none;">
                    <v-icon class="mr-2">mdi-account-circle</v-icon>
                    {{user.name}}
                  </v-btn>
                </template>

              <v-list>
                

                <v-list-item @click="clearWatchlist">
                  <v-list-item-content>
                    Clear Watchlist
                    <!-- <v-btn text style="text-transform: none;" @click="clearWatchlist" elevation="0" class="mr-2  text-left"> 
                      <span>Clear Watchlist</span>
                    </v-btn> -->
                  </v-list-item-content> 
                </v-list-item>
                  <v-list-item to="/logout">
                    <v-list-item-content>Logout</v-list-item-content>
                  </v-list-item>

                

                <!-- <v-list-item @click="clearWatchlist">
                  <v-list-item-content> 

                  </v-list-item-content>

                </v-list-item> -->


              </v-list>
            </v-menu>
 
        <!-- <v-badge color="primary" overlap :content="unreadAlerts.length" :value="unreadAlerts.length">
          <v-btn @click="showAlerts" icon>
            <span><v-icon small>mdi-bell</v-icon></span>
          </v-btn>
        </v-badge> -->
      </v-app-bar>

      <div class="main-wrap">
        <v-main> 
            <router-view></router-view>
            
            <notifications></notifications>
            <audio ref="audio" src="/notification.wav"></audio>
        </v-main>
      </div>
    </div>

      <v-dialog v-if="showDeleteWatchlist" v-model="showDeleteWatchlist" max-width="600">
          <v-card>
              <v-card-title class="text-h5 error" style="color: white" >
                  Delete Watchlist
              </v-card-title>
                <v-divider></v-divider>
              <v-card-text class="pa-5">
                  Are you sure you want to delete your whole watchlist? There is probably some good stuff on there... 
              </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="subdued" text @click="showDeleteWatchlist = false">Just Kidding</v-btn>
                  <v-btn color="error" text @click="confirmDeleteWatchlist"> Kill it</v-btn>
              </v-card-actions>
          </v-card>
          
      </v-dialog>


    <v-snackbar v-model="snackbar.active" 
      top 
      right 
      tile
      multi-line
      :color="snackbarColor"
    >
      <strong>{{snackbar.message}}</strong>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="snackbar.active = false"
        >
          <v-icon small>mdi-close</v-icon> 
        </v-btn>
      </template>
    </v-snackbar>

    <alerts  v-if="alertDrawer"></alerts>
  </v-app>
</template>

<script>
import { EventBus } from './modules/EventBus.js';
import { mapGetters, mapMutations } from 'vuex';
import Alerts from './components/base/Alerts.vue';
import Notifications from './components/base/Notifications.vue';
import { v4 as uuidv4 } from 'uuid';
  const { DateTime } = require("luxon");

export default {
  name: 'App',

  components: {},
 
  data: () => ({ 
    window: {},
    alertDrawer: false, 
    url: "https://auth.tdameritrade.com/auth?",
    client_id: "0KZIIZSWNS4IDEQEEM4HBZSUQZGCAKFG",
    redirectUri: "https://worldganza.com/auth",
    socketEvents:{},
    socket: {},
    snackbar: {
      active: false,
      message: '',
      type: ''
    },
    showDeleteWatchlist: false,
  }),

  created(){ 
    
 
    this.bootstrap();
  
    EventBus.$on('closeAlertDrawer', this.closeAlertDrawer)
    EventBus.$on('bootstrap', this.bootstrap)
    EventBus.$on('tickersAddedToScanner', this.tickersAddedToScanner)
    EventBus.$on('subscribe', this.subscribe)
    EventBus.$on('unsubscribe', this.unsubscribe)
    setTimeout(() => {
        this.snackbar.type = "target"
        this.snackbar.message = "WINNER! $AAPL $1.50 Gain/Share +$500 / 30%"
        this.snackbar.active = true
    }, 3000);


  },
  mounted(){
    window.addEventListener("resize", () => {
       EventBus.$emit('resize');
    });
  } , 
  destroyed(){
    EventBus.$off('closeAlertDrawer', this.closeAlertDrawer);
    EventBus.$off('bootstrap', this.bootstrap)
    EventBus.$off('tickersAddedToScanner', this.tickersAddedToScanner);
    EventBus.$on('subscribe', this.subscribe)
    EventBus.$on('unsubscribe', this.unsubscribe)
  },
  components:{
    Alerts,
    Notifications
  },
  computed: {
    ...mapGetters({
        isLoggedIn: 'authenticationStore/isLoggedIn',
        user: 'authenticationStore/getUser',
        unreadAlerts: 'notificationsStore/getUnreadAlerts',
        viewports: 'baseStore/getViewports',
        activeTicker: 'dashboardStore/getActiveTicker',
    }),
    ...mapMutations({
        setViewports: 'baseStore/setViewports'
    }),
    isValidExpiry(){

      if(!this.user.refresh_token_expiry) return false
      let token_expiry = DateTime.fromSQL(this.user.refresh_token_expiry);
      let now = DateTime.now();
      return now.valueOf() < token_expiry.valueOf()



    }, 
    active_viewports: {
      /* By default get() is used */
      get() {
        return this.viewports
      },
      /* We add a setter */
      set(value) {
        this.$store.commit( 'baseStore/setViewports', value);
      }
    },




    snackbarColor(){
      if(this.snackbar.type === 'filled'){
        //return 'secondary';
      }
      if(this.snackbar.type === 'stopped'){
        return 'error';
      }
      if(this.snackbar.type === 'target'){
        return 'primary';
      }
    }
  }, 
  filters:{
    formatDateTime(date){       
        let d = DateTime.fromSQL(date);
        return d.toLocaleString(DateTime.DATETIME_SHORT); 
    }, 
  }, 
  methods:{
  

    bootstrap(){
    
      if(!this.user.id) return;
      
      this.setSocketEvents();
      this.getAlerts();
      this.getEvents();
      this.getUserScans();
      this.getPreconfiguredScans();
      this.getIndicators();
      this.setUpWebsockets();
      this.setUpActiveTickerInterval();
    }, 
    subscribe(ticker){
      this.socket.emit('subscribe', ticker);
    }, 
    unsubscribe(){
      this.socket.emit('unsubscribe', ticker);
    }, 
    getUserScans(){
      this.$store.dispatch( 'scannerStore/getUserScans');
    },
    closeAlertDrawer(){
      this.alertDrawer = false; 
    }, 
    showAlerts(){
      this.alertDrawer = !this.alertDrawer; 
    },
    setUpActiveTickerInterval(){
      this.$store.dispatch( 'dashboardStore/setUpActiveTickerInterval');
    }, 
    getEvents(){
      this.$store.dispatch( 'notificationsStore/getEvents');
    }, 
    getAlerts(){
      this.$store.dispatch( 'notificationsStore/getAlerts');
    },
    getIndicators(){
      this.$store.dispatch('scannerStore/getIndicators');
    }, 
    getPreconfiguredScans(){
      this.$store.dispatch( 'scannerStore/getPreconfiguredScans');
    }, 
    watchSocket(){
      if(this.user.id){
          this.socket.emit('watch', this.user.id);
        }
    }, 
    setSocketEvents(){
      this.socketEvents = {
        'price_update': (payload) => {     
            console.log("payload", payload)
            this.$store.commit('dashboardStore/updatePrice',  payload);
        },
        'dataset_loaded': (payload) => {     
            console.log("dataset_loaded", payload)
           EventBus.$emit('dataset_loaded', payload);
        },
      
        'scan_update': (payload) => {
             this.getUserScans();
             this.getPreconfiguredScans();
        },
        'set_active': (payload) => {
            this.$store.dispatch('dashboardStore/setTicker',  {
                    ticker: payload.symbol,
                    set_active: true
                });
        },
        'connect': () => {
          if(this.user.id){
            this.socket.emit('watch', this.user.id);
          }
        },
        'scan_notification': (data) => {
            this.$refs.audio.play();
            this.$store.dispatch('notificationsStore/updateAlert', data); 
            EventBus.$emit('show_notification', data);
        },
        'alert_trigger': (data) => {
            this.$refs.audio.play();
            this.$store.dispatch('notificationsStore/updateAlert', data); 
            EventBus.$emit('show_notification', data);
        },
        'event_notification': (data) => {
          console.log("data", data)
           this.$refs.audio.play();
           
          this.$store.commit('notificationsStore/addEvent', data); 
          // EventBus.$emit('show_notification', data);
        },
        
      }
    }, 
    showAddedTickerNotification(updatedScanners){
        this.$refs.audio.play();
    },
    setUpWebsockets(){
        this.socket = io(process.env.VUE_APP_API_HOST, {
          withCredentials: true
        });

        for(const socketEvent in this.socketEvents) {
            this.socket.on(socketEvent, this.socketEvents[socketEvent]);
        }
    },

    authenticate(){
      let url = this.url + "response_type=code&redirect_uri=" + encodeURIComponent(this.redirectUri) + "&client_id=" + this.client_id + "%40AMER.OAUTHAP"; 
      this.window = window.open(url, "Authentication");
    },
    clearWatchlist(){
      this.showDeleteWatchlist = true;
    },
    async confirmDeleteWatchlist(){
        await this.$store.dispatch('scannerStore/deleteWatchlist');
        this.showDeleteWatchlist = false;

    }, 
    tickersAddedToScanner(updatedScanners){
      let date = new Date();
      let alert = {
        id: uuidv4(),
        time: date.getTime() / 1000, 
        type: 'scanner_update', 
        scanners: updatedScanners, 
      }

      this.$refs.audio.play();
      EventBus.$emit('show_notification', alert);

    }
  },
  watch: {
    active_viewports(){
        this.$nextTick(() => {
          EventBus.$emit('resize');
        })
    },
    activeTicker(){
      this.socket.emit('set_active_ticker', this.activeTicker);
    }
  }
};
</script>
<style>
  
  * {
    box-sizing:border-box;
  }

  

  body{
      height: 100vh;
      width: 100vw;
    font-size: .86em;
    background-color: #EEF2F3;
  }
  
  .pricing-table-head {
      color: white;
      background-color: #264653;
  }
  .pricing-table-head-light {
      color: #264653;
      background-color: #e2e2e2;
  }
  .pricing-table-row{
    border-bottom: 1px solid #e2e2e2;
  }
  /* .pricing-table-row:nth-child(even) {
    background-color: #f2f2f2;
  } */
  .pricing-table-field.pricing-table-key{
    text-align: left;
    font-weight: 500
  }
  .pricing-table-field{
    text-align: right;
  }
  .pricing-table{
    font-size: 14px;
  }
  .green-10 { background-color: rgba(42, 157, 143, 1) }
  .green-8 { background-color: rgba(42, 157, 143, .8) }
  .green-6 { background-color: rgba(42, 157, 143, .6) }
  .green-4 { background-color: rgba(42, 157, 143, .4) }
  .green-2 { background-color: rgba(42, 157, 143, .2) }
  .green-0 { background-color: rgba(42, 157, 143, .1) }
  .red-10 { background-color: rgba(231, 111, 81, 1) }
  .red-8 { background-color: rgba(231, 111, 81, .8) }
  .red-6 { background-color: rgba(231, 111, 81, .6) }
  .red-4 { background-color: rgba(231, 111, 81, .4) }
  .red-2 { background-color: rgba(231, 111, 81, .2) }
  .red-0 { background-color: rgba(231, 111, 81, .1) }


  .pricing-table-row.target{
    background-color: rgba(42, 157, 143, .1);
    color: rgba(42, 157, 143, 1)
  }
  .pricing-table-row.stop{
    background-color: rgba(231, 111, 81, .1);
    color: rgba(231, 111, 81, 1)
  }
  .sub-row-container .pricing-table-row:last-child{
    /* border: none; */
  }
  .sub-row-container {
     background-color: #f9f9f9
  }

  .pricing-table-field.win{
    color: rgba(42, 157, 143, 1)
  }
  .pricing-table-field.lose{
    color: rgba(231, 111, 81, 1)
  }
  header.v-app-bar.v-toolbar{
      z-index: 1000;
  }


  /* .compact-form {
    transform: scale(0.875);
    transform-origin: left;
  } */

  .column-filter .v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, 
  .column-filter .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, 
  .column-filter .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, 
  .column-filter .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, 
  .column-filter .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, 
  .column-filter .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot,
  .column-filter .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, 
  .column-filter .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, 
  .column-filter .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot{
      /* min-height: 21px; */
      transform: scale(0.75);
      margin-top: 0;
      transform-origin: left;
  }

  .column-filter .v-input__icon{
      /* height: 16px; */
      transform: scale(0.75);
  }
  .column-filter .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, 
  .column-filter .v-text-field.v-text-field--enclosed .v-text-field__details {
      /* padding: 0 5px; */
  }
  .column-filter .v-input__control{
      /* height: 32px; */
      /* transform: scale(0.75); */
  }
  .column-filter .v-select.v-input--dense .v-select__selection--comma {
      /* margin: 2px 4px 3px 2px; */
  }

  .column-filter .v-btn:not(.v-btn--round).v-size--small {
      height: 28px;
      min-width: 30px;
      padding: 0 12.4444444444px;
  }



.ag-header-cell.ag-floating-filter{
  padding-left: 5px;
  padding-right: 5px;
}

.unset-case {
     text-transform: unset !important;
      letter-spacing: 0.05em !important;
}



    .kpi-label{
        line-height: 18px;
        color: #a0a0a0;
        font-size: 12px;
        font-weight: normal;
    }
    .kpi-metric{
        color: #5d5d5e;
        font-size: 18px;
        font-weight: bold;
        
    }
    .order-simulation{
        border-left: 2px solid white;
        padding-left:30px;
        margin-left: 30px;
    }
    .search-bar{
        padding: 10px;
        border-bottom: 1px solid #e2e2e2

    }
    .chart-container{
        position: relative;
        width: 100%;
    }

</style>